<!--投票产品详情-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <div class="breadTopDiv">
      <el-row class="breadTop">
        <el-col>
          <top-path-list :path-obj="pathObj"></top-path-list>
        </el-col>
      </el-row>
    </div>
    <div class="content-div">
      <el-row>
        <el-col :span="24">
          <div class="giNameDiv">
            {{giName}}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="giictureDiv">
            <img :src="giicture" alt="">
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div v-html="giDescript" class="giDescriptDiv">
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="btnDiv">
          <div class="likeImg"><img src="~@/assets/img/like.png" alt=""></div>
          <div class="number">{{actiVoteCount}}</div>
          <el-button class="to-vote" @click="likeClick()">投票</el-button>
        </el-col>
      </el-row>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
import eventBus from "@/utils/eventBus.js";
export default {
  name: "productDetail",
  components: {
    topPathList
  },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标品牌',
        },
        {
          name: '在线评选',
          path: '/giBrand/onlineSelection/index'
        },
        {
          name: '评选详情',
          path: '/giBrand/onlineSelection/onlineSelectionDetail'
        },
        {
          name: '产品详情',
          path: '/giBrand/onlineSelection/onlineSelectionDetail/productDetail'
        }
      ],
      giName: "",
      giDescript: "",
      giicture: "",
      entryId: "",
      selectionMulSel: "",
      selectionMaxCount: "",
      voteLimit: "",
      actiId: "",
      actiVoteCount: "",
    }
  },
  mounted() {
    //this.pathObj = this.$route.query.pathObj; //面包屑
    this.giName = this.$route.query.giName; //标题
    this.giDescript = this.$route.query.giDescript; //描述
    this.giicture = this.$route.query.giicture; //图片
    //this.giictureUrl = process.env.VUE_APP_HUSSAR_DEFAULT_API + "/attachment/showPicture?image=" + `${this.giicture}`
    this.entryId = this.$route.query.entryId; //id
    this.selectionMulSel = this.$route.query.selectionMulSel; //是否多选
    this.selectionMaxCount = this.$route.query.selectionMaxCount; //最大投票数量
    this.voteLimit = this.$route.query.voteLimit; //投票限制
    this.actiId = this.$route.query.actiId; //投票限制
    this.actiVoteCount = this.$route.query.actiVoteCount; //投票数量
  },
  methods: {
    likeClick() {
      //判断是否登录
      if (sessionStorage.getItem("HussarToken")) {
        //调用投票接口
        let params = {}
        params.entryId = this.entryId //投票对象id
        params.selectionMulSel = this.selectionMulSel //是否多选
        params.selectionMaxCount = this.selectionMaxCount //最大投票数量
        params.actiId = this.actiId
        if (this.voteLimit == "" || this.voteLimit == undefined) {
          params.voteLimit = "0"
        } else {
          params.voteLimit = this.voteLimit //投票限制
        }

        let tokenDataTemp = sessionStorage.getItem("HussarToken")
        let tokenData = JSON.parse(tokenDataTemp)

        axios({
          method: "post",
          url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/onlineselection/voteOperate',
          headers: {
            "Authorization": tokenData //sessionStorage.getItem("HussarToken")
          },
          data: params
        }).then(res => {
          if (res.data.code == 200 || res.data.code == 10000) {
            this.$message.success("投票成功")
            //刷新页面
            this.voteClick()
          } else {
            this.$message.warning(res.data.msg)
          }
        }).catch(err => {
          this.$message.error('请求失败')
        })
      } else {
        //弹出登录弹窗
        eventBus.$emit('toLogin');
      }
    },

    //刷新票数
    voteClick() {
      let tokenDataTemp = sessionStorage.getItem("HussarToken")
      let tokenData = JSON.parse(tokenDataTemp)
      let params = {}
      params.current = 1
      params.size = 1
      params.businessId = this.$route.query.businessId
      params.registrationName = this.$route.query.giName
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/onlineselection/achieveActiEntry',
        headers: {
          "Authorization": tokenData || "" //sessionStorage.getItem("HussarToken")
        },
        data: params
      }).then(res => {
        if (res.data.code == 200 || res.data.code == 10000) {
          this.actiVoteCount = res.data.data.actiVote[0].actiVoteCount

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .main-div {
    background-color: #f8f9fa !important;
    text-align: center;
  }
  .breadTopDiv {
    text-align: center;
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  }
  .content-div {
    min-height: 525px;
    margin: auto;
    padding: 32px 20px 12px 20px;
    width: 1160px;
    background: #FFFFFF;
    .giNameDiv {
      font-size: 34px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #2E2E2E;
      line-height: 51px;
    }
    .giictureDiv {
      img {
        width: 455px;
        height: 285px;
      }
    }
    .giDescriptDiv {
      text-align: left;
    }
    .btnDiv {
      display: flex;
      justify-content: center;
      align-items: center;

      .likeImg {
        margin: auto 0px auto 15px;
      }
      .number {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #186CF5;
        line-height: 24px;
        margin: auto 10px auto 10px;
      }

      .to-vote {
        width: 72px;
        height: 32px;
        background: #EEF4FF;
        border-radius: 5px;
        border: 1px solid #186CF5;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #186CF5;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        margin-left: 50px;
      }
    }
  }

  ::v-deep .el-row {
    margin: 20px 0;
  }
  .infoTitle {
    font-weight: bold;
    margin: 0 0 20px 0;
    font-size: 20px;
    color: #303133;
  }
</style>
